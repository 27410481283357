












import { Component, Prop, Vue, Watch } from "vue-property-decorator";
@Component({})
export default class FarmOldLabelNumber extends Vue {
    @Prop() TitleldLabelNumber: any;

    @Prop() clearAllData: any;

    private originalNumber: any = "";

    get oldLabelNumberStr() {
        return this.$t("common.placeholder.enter") ;
    }

    private changeOldLabelNumber() {
        this.$emit("changeOriginalNumber", this.originalNumber);
    }
}
