






















import { Component, Prop, Vue, Watch } from "vue-property-decorator";
@Component({})
export default class FarmEnterprise extends Vue {
    @Prop() FarmEnterpriseList: any;
    @Prop() groupCode: any;
    @Prop() clearAllData: any;

    private companyId: any = "";

    public props: any = {
        expandTrigger: "hover",
        value: "id",
        label: "name"
    };

    private changeEnterprise() {
        this.$emit("changeEnterprise", this.companyId);
    }

    @Watch("groupCode", { immediate: true })
    private ChangeFarmHourse(newVal: any[]) {
        if (newVal && newVal.length != 0) {
            if (this.groupCode != undefined) {
                this.companyId = this.groupCode;
            }
        }
    }
}
