






















import { Component, Prop, Vue, Watch } from "vue-property-decorator";
@Component({})
export default class FarmHouse extends Vue {
    @Prop() FarmHouse: any;
    @Prop() FarmId: any;
    @Prop() isqueryflag: any;

    private farmId: any = "";
    private isDisabled: boolean = false;

    private changeFarmHouse() {
        this.$emit("changeFarmHouse", this.farmId);
    }

    // @Watch('FarmId' , {immediate:true})
    // private changeFarmId(newVal:string){
    //     if(newVal == undefined) return
    //     if(this.FarmHouse && this.FarmHouse.length == 0){
    //         this.farmId = newVal
    //     }
    // }

    @Watch("isqueryflag", { immediate: true, deep: true })
    private changeIsQueryFlagaa(newVal: boolean) {
        // alert('in')
        // if(newVal){
        //     this.isDisabled = true
        // }else{
        //     this.isDisabled = false
        // }
        // console.log(this.isDisabled)
    }

    @Watch("FarmHouse", { immediate: true })
    private ChangeFarmHourse(newVal: any[]) {
        if (newVal && newVal.length != 0) {
            if (this.FarmId != undefined) {
                this.farmId = this.FarmId;
            }
        }
    }
}
