import { BasicsClass } from '@/common/BasicsClass';
import { CountExceptionFrom } from '@/common/Views/basics/countException/countException';
import { HandleTable } from '@/common/Views/comm/handleTable';
import { Util } from '@/common/Views/comm/util';

export class HealthWarning extends BasicsClass {

    public getTable(form: CountExceptionFrom, tablePage: HandleTable,isInternational:any , cb: Function) {
        this.getTableList({
            ...form,
            groupCode: form.groupCode[form.groupCode.length - 1],
            labelNumber: form.number,
            startTime: Util.dateUtil(form.time[0]),
            endTime: Util.dateUtil(form.time[1]),
        }, tablePage, '/animal/web/healthWarning/list', cb);
    }

    public exportTable(form: CountExceptionFrom) {
        this.getFile('/animal/web/healthWarning/export', form);
    }

    public exportTable1(form: CountExceptionFrom) {
        this.getFile('/animal/web/oestrusAlert/exporOestrusAlertList', form);
    }

    public getCount(labelNumber: string, cb: Function) {
        this.BasicGet('/animal/web/healthWarning/getCount', {
            labelNumber
        }, false, false, cb)
    }
    public ignoreWarning(labelNumberList:any, cb: Function) {
        this.BasicPost1('/animal/web/healthWarning/ignoreWarning', {
            labelNumberList
        }, true, true, true, cb)
    }
    public ignoreDeath(ids:any, cb: Function) {
        this.BasicPost1('/animal/web/newBigScreenAndDeath/ignoreDeath', {
            ids
        }, true, true, true, cb)
    }
    public dealAll(idList:any, cb: Function) {
        this.BasicPost1('/animal/web/oestrusAlert/dealAll', 
            idList
        , true, true, true, cb)
    }
    public ignoreAll(ids:any, cb: Function) {
        this.BasicPost1('/animal/web/deviceAlarm/ignoreAll', {
            ids
        }, true, true, true, cb)
    }
}
