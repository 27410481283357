



















































































































































































































































































































































































































import { Component, Watch, Vue } from "vue-property-decorator";
//引入请求ts
import { FarmData } from "@/views/components/FarmGetData";
import { DeepLoop } from "@/common/deepLoop/deepLoop";
//引入养殖地模板
import FarmAdress from "@/views/components/input/FarmAdress.vue";
//引入养殖企业模板
import FarmEnterprise from "@/views/components/input/FarmEnterprise.vue";
//引入养殖场模板
import FarmHouse from "@/views/components/input/FarmHouse.vue";

//引入通用下拉框
import SelectComponentVue from "@/views/basics/typeConfig/components/components/SelectComponentVue.vue";
import { HealthWarning } from "@/common/Views/healthWarning/healthWarning";
import FarmOriginalNumber from "@/views/components/input/FarmOriginalNumber.vue";

import { dateFun } from "@/common/date";
@Component({
    components: {
        FarmAdress,
        FarmEnterprise,
        FarmHouse,
        SelectComponentVue,
        FarmOriginalNumber,
    },
})
export default class aiOestrusEstimate extends Vue {
    private form: any = {
        groupCode: "",
        companyId: "",
        farmId: "",
        houseId: "",
        labelNumber: "",
        oldLabelNumber: "",
        originalNumber: "",
        deviceType: "",
        dealStatus: "",
    };
    private idList: any = {
        deviceListIds: [],
        device2ListIds: [],
    };
    private SelectData: any = "";
    private langType = this.$i18n.locale;
    private infoType = false;
    public infoData = {};
    //展示详细信息
    private ShowDetail(row: any) {
        this.infoData = row;
        this.infoType = true;
    }

    //养殖地数据
    private FarmAdressList: any[] = [];
    private getFarmAdress(cb: Function) {
        new FarmData().getFarmAdress((res: any) => {
            const data = new DeepLoop().deepCopy(res.data);
            this.FarmAdressList = data;
            cb ? cb() : "";
        });
    }
    private changeAdress(groupCode: any) {
        //赋值
        this.form.groupCode = groupCode[groupCode.length - 1];
        //清除企业数据
        this.FarmEnterpriseList = [];
        //清除实例中的属性
        this.$refs.FarmEnterprise.companyId = "";
        // this.$refs.FarmHouseRef.farmId = ''
        //清除form中的属性
        this.form.companyId = "";
        this.form.farmId = "";
        //重新请求养殖企业的数据
        this.getFarmEnterprise();

        //清除养殖场数据
        this.FarmHouse = [];
        // //重新请求养殖厂数据
        this.getFarmHouse();
    }

    //养殖企业数据
    private FarmEnterpriseList: any[] = [];
    private unshiftPlaceholder = this.$t("common.placeholder.all");
    private getFarmEnterprise() {
        new FarmData().getFarmEnterprise(this.form.groupCode, (data: any) => {
            this.FarmEnterpriseList = data.data;
            this.FarmEnterpriseList.unshift({
                id: "",
                name: this.unshiftPlaceholder,
            });
        });
    }
    @Watch("$i18n.locale", { immediate: true })
    private handlerLocal() {
        this.unshiftPlaceholder = this.$t("common.placeholder.all");
        this.FarmEnterpriseList[0].name = this.unshiftPlaceholder;

        this.FarmHouse[0].name = this.unshiftPlaceholder;
    }
    private changeEnterprise(companyId: any) {
        //赋值
        this.form.companyId = companyId;

        //清除养殖场数据
        this.FarmHouse = [];
        //清除实例中的属性
        this.$refs.FarmHouseRef.farmId = "";
        //清除form中的属性
        this.form.farmId = "";
        //重新请求养殖厂数据
        this.getFarmHouse();
    }
    private checCheckboxkMethod2(item: any) {
        return item.row.dealStatus === 0;
    }
    //养殖厂数据
    private FarmHouse: any[] = [];
    private getFarmHouse() {
        new FarmData().getFarmHouse(
            this.form.groupCode,
            this.form.companyId,
            (data: any) => {
                const FarmHouseData = new DeepLoop().deepCopy(data.data);

                FarmHouseData.unshift({
                    id: "",
                    name: this.unshiftPlaceholder,
                });
                this.FarmHouse = FarmHouseData;
            }
        );
    }
    private changeFarmHouse(farmId: any) {
        //赋值
        this.form.farmId = farmId;
        //执行请求 养殖舍 栏
        this.getFarmPen();
    }

    //养殖舍
    private FarmPenList: any[] = [];
    private getFarmPen() {
        new FarmData().getFarmPen(this.form.farmId, (data: any) => {
            this.FarmPenList = [];
            data.data.forEach((item: any) => {
                this.FarmPenList.push({
                    id: item.id,
                    name: item.placeName,
                });
            });
            this.FarmPenList.unshift({
                id: "",
                name: this.$t("common.placeholder.all"),
            });
        });
    }
    private SetForageTypeValue(val: string) {
        this.form.houseId = val;
    }
    private selectList: String[] = [];
    private selectChangeEvent(e: any) {
        console.log(e);

        this.selectList = this.getListID(e.selection);
        console.log(this.selectList);
    }
    private selectAllEvent(e: any) {
        console.log(e);

        this.selectList = this.getListID(e.selection);
        console.log(this.selectList);
    }
    private getListID(data: any[]) {
        let result: String[] = [];
        data.forEach((item: any) => {
            result.push(item.id);
        });
        return result;
    }
    private Ignore() {
        if (this.selectList.length == 0) {
            this.$message({
                message: this.$t("ai_module.oestrus.place_1") as string,
                type: "warning",
            });
            return;
        }
        const keysToExtract = this.selectList;

        // 使用 map 方法从 this.tableData 中提取对象
        console.log(this.tableData);

        const extractedObjects = keysToExtract.map((id) => {
            // 使用 find 方法找到与当前键匹配的对象
            console.log(id, "id");

            return this.tableData.find((obj) => obj.id === id);
        });
        console.log(extractedObjects, "id");
        extractedObjects.forEach((item: any) => {
            if (item.deviceType == 1) {
                this.idList.deviceListIds.push(item.id);
            } else {
                this.idList.device2ListIds.push(item.id);
            }
        });
        console.log(this.idList);

        this.$confirm(
            this.$t("ai_module.oestrus.place_2") as string,
            this.$t("ai_module.oestrus.place_3") as string,
            {
                confirmButtonText: this.$t("common.confirm") as string,
                cancelButtonText: this.$t("common.cencel") as string,
                type: "warning",
            }
        )
            .then(() => {
                new HealthWarning().dealAll(this.idList, (res: any) => {
                    this.selectList = [];
                    this.$message({
                        message: this.$t("ai_module.oestrus.place_4") as string,
                        type: "success",
                    });
                    this.getTableList();
                });
            })
            .catch(() => {});
    }
    @Watch("SelectData")
    private changeSelectData(newVal: any) {
        if (newVal.length == 0) return;
        this.form.startTime =
            new dateFun().returnDate(newVal[0], "-") +
            " " +
            new dateFun().returnHourMinuteSecend(newVal[0], ":");
        this.form.endTime =
            new dateFun().returnDate(newVal[1], "-") +
            " " +
            new dateFun().returnHourMinuteSecend(newVal[1], ":");
    }

    private tableData: any[] = [];
    private tablePage: any = {
        totalCount: 0,
        pageNo: 1,
        pageSize: 10,
        pageSizes: [10, 20, 50, 100, 200, 500],
    };
    private getTableList() {
        new FarmData().getAiOestrusEstimateList(
            this.form,
            this.tablePage,
            (data: any) => {
                this.tableData = data.data;
                this.tablePage.totalCount = data.page.totalCount;
            }
        );
    }
    private handlePageChange(val: any) {
        //分页 事件
        this.tablePage.pageNo = val.currentPage;
        this.tablePage.pageSize = val.pageSize;

        //请求列表数据
        this.getTableList();
    }

    private search() {
        this.getTableList();
    }
    private restForm() {
        this.form = {
            groupCode: "",
            companyId: "",
            farmId: "",
            houseId: "",
            labelNumber: "",
            oldLabelNumber: "",
            originalNumber: "",
            deviceType: "",
            dealStatus: "",
        };
        (this.$refs.OriginalNumberRef as any).originalNumber = "";
        this.SelectData = "";
        this.getFun();
    }
    private exportlableList() {
        new HealthWarning().exportTable1(this.form);
    }

    private ToNewPage(item: any) {
        this.$router.push({
            path: "activityCurve",
            query: {
                item: JSON.stringify(item),
            },
        });
    }

    private getFun() {
        this.getFarmAdress(() => {
            this.getTableList();
        });
        this.getFarmEnterprise();
        this.getFarmHouse();
    }
    //原厂耳标
    private changeOriginalNumber(originalNumber: any) {
        //赋值
        this.form.originalNumber = originalNumber;
    }

    activated() {
        this.getFun();
    }
}
