import { render, staticRenderFns } from "./SelectComponentVue.vue?vue&type=template&id=9d8f19d6&"
import script from "./SelectComponentVue.vue?vue&type=script&lang=ts&"
export * from "./SelectComponentVue.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports