
















import { Component, Prop, Vue, Watch } from "vue-property-decorator";
@Component({})
export default class FarmAdress extends Vue {
    @Prop() FarmAdressList: any;

    private groupCode: any = [];

    public props: any = {
        expandTrigger: "hover",
        value: "id",
        label: "name"
    };

    private changeFarmAdressList(): void {
        //emit  清空养殖企业数组
        this.$emit("changeAdress", this.groupCode);
    }
}
