import { BasicsClass } from '@/common/BasicsClass';
import { Util } from '@/common/Views/comm/util';

export class FarmData extends BasicsClass {
    //请求养殖地
    public getFarmAdress(cb: Function) {
        this.BasicGet('/animal/web/provinceCityDistrict/getForGroupCode', {}, false, false, cb)
    }

    //请求养殖企业
    public getFarmEnterprise(groupCode: any, cb: Function) {
        this.BasicGet('/animal/web/farm/getCompanyList', { groupCode }, false, false, cb)
    }

    //请求养殖场
    public getFarmHouse(groupCode: any, companyId: any, cb: Function) {
        this.BasicGet('/animal/web/farm/getFarmList', { groupCode, companyId }, false, false, cb)
    }

    //请求养殖栏养殖舍
    public getFarmPen(farmId: string, cb: Function) {
        this.BasicGet('/animal/web/animalFarmPlace/getThisAnimalFarmPlaceList', { farmId }, false, true, cb);
    }

    //品种
    public getAllBreed(farmId: any, cb: Function) {
        this.BasicGet('/animal/web/confTypeInfo/selectAllBreed', { farmId }, false, false, cb)
    }
    //品种
    public getAllBreedAdd(farmId: any, cb: Function) {
        this.BasicGet('/animal/web/femaleBirthConfig/breedList', { farmId }, false, false, cb)
    }

    //品种3.3.5
    public getAllBreedNew(typeId: any, cb: Function) {
        this.BasicGet('/animal/web/confTypeInfo/listForType', { typeId }, false, false, cb)
    }

    //类型  
    public getAllType(farmId: any, cb: Function) {
        this.BasicGet('/animal/web/confTypeInfo/selectAll', { farmId }, false, false, cb)
    }

    //模板导出
    public exportlableList2(url: string, form: any, cb: Function): void {
        this.BasicGet1(url, { ...form }, false, false, cb)
    }

    //档案详情页面
    public getAnimalFileDetail(markId: any, cb: Function) {
        this.BasicGet('/animal/web/animalMark/getAnimalFileDetail', { markId }, false, false, cb)
    }

    //获取动物区块链信息
    public getBlockInfo(markId: string, cb: Function) {
        this.BasicGet('/animal/web/blockChain/getBlockInfo', {
            markId,
        }, false, true, cb);
    }

    //获取电子耳标历史列表
    public getOldLabelNumberList(markId: string, cb: Function) {
        this.BasicGet('/animal/web/animalMark/getHistoryLabel', {
            markId,
        }, false, true, cb);
    }
    //获取母扣号历史列表
    public getOldOldLabelNumberList(markId: string, cb: Function) {
        this.BasicGet('/animal/web/animalMark/getHistoryOldLabel', {
            markId,
        }, false, true, cb);
    }
    public getAiOestrusEstimateList(form: any, page: any, cb: Function) {
        this.BasicGet('/animal/web/oestrusAlert/getOestrusAlertList', { ...form, ...page }, false, false, cb)
    }
}


//时间
// const time = Util.handleStartTimeAndEndTimenohms(form.time);