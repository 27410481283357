
















import { Watch, Prop, Component, Vue } from "vue-property-decorator";
@Component({})
export default class SelectComponentVue extends Vue {
    @Prop() FormSelectVal: any;
    @Prop() SelectList: any;
    @Prop() placeholderText: any;
    @Prop() Width: any;

    private SelectVal: any = ""; //当前组件所绑定的值

    @Watch("SelectVal")
    private cjamgeSelectVal(newVal: string) {
        this.$emit("SetSelectListValue", newVal); //设置下拉框选择的值
    }

    @Watch("FormSelectVal", { immediate: true, deep: true }) //监视form传递过来的绑定的值  如果不为假赋值  编辑使用
    private changeSelectData(newVal: any) {
        if (newVal == undefined) return;
        this.SelectVal = newVal;
    }

    @Watch("SelectList", { immediate: true, deep: true }) //监视传递过来的数组 统一转换为一样的  key
    private changeSelectList(newVal: any) {
        if (newVal == undefined) return;
        // if(newVal[0])
        // let newArray:any[] = []
        // newVal.map((item:any)=>{
        //     let obj = {}
        //     obj = {name: item.bankName,farmId: item.bankId}
        //     newArray.push(obj);
        // })
        // this.FarmDataList = newArray
    }
}
